import React, { useState, useEffect } from "react";
import "./App.css";
import { MDBContainer, MDBRow, MDBCol } from "mdbreact";
import Amplify from "aws-amplify";
import awsExports from "./aws-exports";
import { withAuthenticator, AmplifySignOut } from "@aws-amplify/ui-react";
import { API, Auth } from "aws-amplify";
import Footer from "./component/Footer";
import axios from "axios";
import ImageUploader from "react-images-upload";
import { css } from "@emotion/core";
import RingLoader from "react-spinners/RingLoader";
import Camera from 'react-html5-camera-photo';
import 'react-html5-camera-photo/build/css/index.css';
import ImagePreview from './component/ImagePreview';
import ModalPage from './component/modal'
Amplify.configure(awsExports);

function App(props) {
  const [pictures, setPictures] = useState([]);
  const [epfNumber, setEPFnum] = useState("E00000");
  const [email, setEmail] = useState("user@dimolanka.com");
  const [user, setUser] = useState([]);
  const [form, setForm] = useState([{}]);
  const [imageBuff, setImageBuff] = useState("");
  const [loadingPage, setLoadingPage] = useState(true);
  const [NoImages, setNoImages] = useState("Loading photos ...");
  const [dataUri, setDataUri] = useState("");
  const [loading, setloading] = useState(true);
  const [withPreview, setwithPreview] = useState(true);
  const [retake, setretake] = useState(false);

  const isFullscreen = false;
  const myInit = {
    // OPTIONAL
    headers: {}, // OPTIONAL
    response: true, // OPTIONAL (return the entire Axios response object instead of only response.data)
    queryStringParameters: {
      // OPTIONAL
      user_email: email,
      epf: epfNumber,
    },
  };
  const override = css`
  display: block;
  margin: 0 auto;
  position: fixed;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
`;
  const onDrop = (picture) => {
    console.log(picture);
    setPictures([picture]);
    setwithPreview(true);
    setDataUri("");
  };

  function handleTakePhotoAnimationDone(dataUri) {
    console.log('takePhoto');
    setDataUri(dataUri);
    const url = dataUri;
    fetch(url)
      .then(res => res.blob())
      .then(blob => {
        const file = [new File([blob], "File name", { type: "image/png" })];
        setPictures([file]);
        console.log("done");
      })
    setwithPreview(false);
    setretake(true);
  }



  const handleSubmit = (evt) => {
    evt.preventDefault();
    setloading(true);
    //TODO perform client side validations
    setForm([
      {
        photo: pictures,
      },
    ]);
  };


  function retakeBtnfunction() {
    console.log('retake button cliked');
    setDataUri("");
    setretake(false);

  }

  async function getUserDetails() {
    // var requestOptions = {
    //   method: "GET",
    //   redirect: "follow",
    // };

    // fetch(
    //   "https://sv9l9wzw02.execute-api.us-east-1.amazonaws.com/devface/faceregapi/o",
    //   requestOptions
    // )
    //   .then((response) => response.text())
    //   .then((result) => console.log("axio result",result))
    //   .catch((error) => console.log("error", error));

    //   const myInit11 = {
    //     // OPTIONAL
    //     headers: {}, // OPTIONAL
    //     response: true, // OPTIONAL (return the entire Axios response object instead of only response.data)

    //   };


    // API.get('faceregapi', '/faceregapi/p', myInit11)
    //   .then(response => {
    //     // Add your code here
    //     console.log("responce 2",response)
    //   })
    //   .catch(error => {
    //     console.log(error)
    //   });


    Auth.currentAuthenticatedUser({
      bypassCache: false, // Optional, By default is false. If set to true, this call will send a request to Cognito to get the latest user data
    })
      .then((user_temp) => {
        console.log(user_temp);
        setUser(user_temp);
        setEPFnum(user_temp.attributes["custom:epf"]);
        setEmail(user_temp.attributes.email);
      })
      .catch((err) => {
        console.log(err);
      });
  }

  async function postFaceData() {
    //get presinget url from lambda
    //upload image to s3
    //send upload sucess messege to lambda
    //lambada will add face and send sucess msg
    if ("photo" in form[0]) {
      const fileObject = form[0].photo[0][0];
      // get image extention
      const ContentType = fileObject.type;
      const postFaceInit = {
        // OPTIONAL
        headers: {}, // OPTIONAL
        response: true, // OPTIONAL (return the entire Axios response object instead of only response.data)
        body: {
          token: user.signInUserSession.accessToken.jwtToken,
          ContentType,
        },
      };
      console.log(postFaceInit);

      API.post("faceregapi", "/faceregapi/addnew", postFaceInit)
        .then((response) => {
          // Add your code here
          console.log(response);
          let putUrl = response.data.url;

          // Put the fileType in the headers for the upload
          let putOptions = {
            headers: {
              ContentType,
            },
          };
          axios
            .put(putUrl, fileObject, putOptions)
            .then((result) => {
              console.log("Response from s3");
              console.log(result);
              sendUploadsucess(epfNumber, ContentType);
            })
            .catch((error) => {
              console.log(error);
              // alert("ERROR " + JSON.stringify(error));
            });
        })
        .catch((error) => {
          console.log(error);
        });
    }
  }

  function sendUploadsucess(epf, type) {
    const addToRekgInit = {
      // OPTIONAL
      headers: {}, // OPTIONAL
      response: true, // OPTIONAL (return the entire Axios response object instead of only response.data)
      body: {
        epf,
        type,
      },
    };
    API.post("faceregapi", "/faceregapi/addtoRekognition", addToRekgInit)
      .then((response) => {
        // Add your code here
        console.log(response);
        setloading(false);
        //TODO show photo sucess fully added messege
        window.location.reload(); 
      })
      .catch((error) => {
        console.log(error);
        setloading(false);
      });
  }
  //2
  async function getFaceData() {
    const faceDataInit = {
      // OPTIONAL
      headers: {
      }, // OPTIONAL
      response: true, // OPTIONAL (return the entire Axios response object instead of only response.data)
      queryStringParameters: {
        token: user.signInUserSession.accessToken.jwtToken,
      },
    };

    const userFaceData = await API.get(
      "faceregapi",
      "/faceregapi/userimages",
      faceDataInit
      //here
    )
      .then((response) => {
        // Obtain a blob: URL for the image data.
        console.log(response);
        if (response.data.error) {
          //show no photos added yet
          setNoImages("no photos added yet");
          setloading(false);
        } else {
          setNoImages("");
          setImageBuff(response.data.url);
          setloading(false);
        }
      })
      .catch((error) => {
        console.log(error);
      });
  }

  useEffect(() => {
    getUserDetails(); //1
  }, []);

  useEffect(() => {
    if (epfNumber !== "E00000") {
      console.log("epf number change" + epfNumber);
      getFaceData(); //2
    }
  }, [epfNumber]);

  useEffect(() => {
    postFaceData(); //4
  }, form);

  return (
    <div className="App">
      <div id="overlay" style={{ display: (loading) ? "block" : "none" }}></div>
      <MDBContainer className="main-container">
        <MDBRow>
          <MDBCol size="4"></MDBCol>
          <MDBCol size="4"></MDBCol>
          <MDBCol size="4">
            <AmplifySignOut id="logout" />
            {/* <a
              className="logoutBtn"
              onClick={() => {
               
                // document.getElementById("logout").firstChild().click();
              }}
            >
              Sign Out
            </a> */}
          </MDBCol>
        </MDBRow>
        <MDBRow>
          <MDBCol size="12" >
            <p className="h5 text-center mb-4">Upload Photos</p>
          </MDBCol>

        </MDBRow>
        <MDBRow center>
          <MDBCol size='10' style={{ fontSize: '12px' }} >
            <MDBRow >
              <MDBCol className='text-left' size='2' >EPF</MDBCol>
              <MDBCol className='text-right' size='1'  >:</MDBCol>
              <MDBCol className='text-left' >{epfNumber}</MDBCol>
            </MDBRow>
            <MDBRow style={{ marginTop: '10px' }}>
              <MDBCol className='text-left' size='2' >Email</MDBCol>
              <MDBCol className='text-right' size='1' >:</MDBCol>
              <MDBCol className='text-left'>{email}</MDBCol>
            </MDBRow>
            <MDBRow style={{ marginTop: '10px' }}>
              <MDBCol className='text-left' size='2' >Image</MDBCol>
              <MDBCol className='text-right' size='1' >:</MDBCol>
              <MDBCol className='text-left'>
                <img src={imageBuff} style={{ width: '100%' }} />
                <p>{NoImages}</p>
              </MDBCol>
            </MDBRow>
          </MDBCol>


        </MDBRow>

        <MDBRow center>

          <MDBCol size='9'>
            <form >
              <ImageUploader
                {...props}
                withIcon={true}
                onChange={onDrop}
                imgExtension={[".jpg", ".png"]}
                maxFileSize={5242880}
                withPreview={withPreview}
                buttonText="Select Photo"
                className="ImageUploader"
                singleImage={true}

              />
            </form>
          </MDBCol>
        </MDBRow>

        <MDBRow>
          <MDBCol size="12">
            <p className="text-center" style={{ marginTop: '1rem', fontSize: '22px' }}>
              OR Take Photo </p>
          </MDBCol>
        </MDBRow>
        <MDBRow center>
          <MDBCol>
            {
              (dataUri)
                ? <ImagePreview dataUri={dataUri}
                  isFullscreen={isFullscreen}
                />
                : <Camera onTakePhotoAnimationDone={handleTakePhotoAnimationDone}
                  isFullscreen={isFullscreen}
                />
            }
          </MDBCol>
        </MDBRow>

        <MDBRow size='8' center style={{ marginTop: '10px' }}>
          <MDBCol>
            <button className="submitBtn" onClick={handleSubmit}>
              Submit
                </button>
          </MDBCol>
          <MDBCol style={{ display: (retake) ? "block" : "none" }}>
            <button className="retakeBtn" onClick={retakeBtnfunction}>
              Retake
                </button>
          </MDBCol>
        </MDBRow>
      </MDBContainer>


      <Footer />

      <RingLoader
        css={override}
        size={150}
        color={"#accf37"}
        loading={loading}
      />

      {/* <ModalPage style={{}}/> */}


    </div>
  );
}

const styles = {
  container: {
    width: "auto",
    margin: "0 auto",
    display: "flex",
    flex: 1,
    flexDirection: "column",
    justifyContent: "center",
    padding: 20,
  },
  left: { width: "100px", textAlign: "left" },
  middle: { width: "10px" },
  right: {},
  long: { width: "200px" },
  Camara: { display: "none" },

};
export default withAuthenticator(App);
