import React, { Component } from 'react';
import { MDBContainer, MDBBtn, MDBModal, MDBModalBody, MDBModalHeader, MDBModalFooter } from 'mdbreact';
import '../sucess-error-icon.less';

class ModalPage extends Component {
    state = {
        modal2: false,
        modal3: false,
        modal4: false,
        modal5: false
    }

    toggle = nr => () => {
        let modalNumber = 'modal' + nr
        this.setState({
            [modalNumber]: !this.state[modalNumber]
        });
    }

    render() {

        return (
            <MDBContainer>
                <MDBBtn color="primary" onClick={this.toggle(3)}>Small modal</MDBBtn>
                <MDBModal isOpen={this.state.modal3} toggle={this.toggle(3)} size="sm">
                    <MDBModalHeader toggle={this.toggle(3)}>MDBModal title</MDBModalHeader>
                    <MDBModalBody>
                    <svg version="1.1" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 130.2 130.2">
  <circle className="path circle" fill="none" stroke="#73AF55" strokeWidth="6" strokeMiterlimit="10" cx="65.1" cy="65.1" r="62.1"/>
  <polyline className="path check" fill="none" stroke="#73AF55" strokeWidth="6" strokeLinecap="round" strokeMiterlimit="10" points="100.2,40.2 51.5,88.8 29.8,67.5 "/>
</svg>
<p className="success">Oh Yeah!</p>

<svg version="1.1" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 130.2 130.2">
  <circle className="path circle" fill="none" stroke="#D06079" strokeWidth="6" strokeMiterlimit="10" cx="65.1" cy="65.1" r="62.1"/>
  <line className="path line" fill="none" stroke="#D06079" strokeWidth="6" strokeLinecap="round" strokeMiterlimit="10" x1="34.4" y1="37.9" x2="95.8" y2="92.3"/>
  <line className="path line" fill="none" stroke="#D06079" strokeWidth="6" strokeLinecap="round" strokeMiterlimit="10" x1="95.8" y1="38" x2="34.4" y2="92.2"/>
</svg>
<p className="error">Bummer!</p>
      </MDBModalBody>
                    <MDBModalFooter>
                        <MDBBtn color="secondary" size="sm" onClick={this.toggle(3)}>Close</MDBBtn>
                        <MDBBtn color="primary" size="sm">Save changes</MDBBtn>
                    </MDBModalFooter>
                </MDBModal>
            </MDBContainer>
        );
    }
}

export default ModalPage;