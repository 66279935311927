/* eslint-disable */
// WARNING: DO NOT EDIT. This file is automatically generated by AWS Amplify. It will be overwritten.

const awsmobile = {
    "aws_project_region": "us-east-1",
    "aws_cognito_identity_pool_id": "us-east-1:31976bc7-b675-4ff0-a991-3ff65bfe9702",
    "aws_cognito_region": "us-east-1",
    "aws_user_pools_id": "us-east-1_86OnysDb2",
    "aws_user_pools_web_client_id": "4jo69vrrtq9j6uo16ggt03lfle",
    "oauth": {},
    "aws_user_files_s3_bucket": "face-clock-bucket-devface",
    "aws_user_files_s3_bucket_region": "us-east-1",
    "aws_cloud_logic_custom": [
        {
            "name": "userapi",
            "endpoint": "https://sbcsq8a25j.execute-api.us-east-1.amazonaws.com/devface",
            "region": "us-east-1"
        },
        {
            "name": "faceregapi",
            "endpoint": "https://sv9l9wzw02.execute-api.us-east-1.amazonaws.com/devface",
            "region": "us-east-1"
        },
        {
            "name": "savetodbapi",
            "endpoint": "https://8djf8qcaf8.execute-api.us-east-1.amazonaws.com/devface",
            "region": "us-east-1"
        }
    ],
    "predictions": {
        "identify": {
            "identifyEntities": {
                "proxy": false,
                "region": "us-east-1",
                "celebrityDetectionEnabled": true,
                "defaults": {
                    "collectionId": "clockAttendance-devface",
                    "maxEntities": 1
                }
            }
        }
    }
};


export default awsmobile;
